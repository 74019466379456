import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(38.000000, 29.000000)">
        <path
          d="M0.227273 35V0.0909081H12.0227C14.75 0.0909081 16.9886 0.556817 18.7386 1.48864C20.4886 2.40909 21.7841 3.67614 22.625 5.28977C23.4659 6.90341 23.8864 8.73864 23.8864 10.7955C23.8864 12.8523 23.4659 14.6761 22.625 16.267C21.7841 17.858 20.4943 19.108 18.7557 20.017C17.017 20.9148 14.7955 21.3636 12.0909 21.3636H2.54545V17.5455H11.9545C13.8182 17.5455 15.3182 17.2727 16.4545 16.7273C17.6023 16.1818 18.4318 15.4091 18.9432 14.4091C19.4659 13.3977 19.7273 12.1932 19.7273 10.7955C19.7273 9.39773 19.4659 8.17614 18.9432 7.13068C18.4205 6.08523 17.5852 5.27841 16.4375 4.71023C15.2898 4.13068 13.7727 3.84091 11.8864 3.84091H4.45455V35H0.227273ZM16.6591 19.3182L25.25 35H20.3409L11.8864 19.3182H16.6591Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
